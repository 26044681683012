<script setup>
import {useSlots} from "vue";
import Modal from "./Modal.vue";

const emit = defineEmits(["close"]);

defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    maxWidth: {
        type: String,
        default: "xl",
    },
    titleClass: String,
    closeable: {
        type: Boolean,
        default: true,
    },
});

const close = () => emit("close");

const slots = useSlots();
</script>

<template>
    <Modal
        :closeable="closeable"
        :max-width="maxWidth"
        :show="show"
        @close="close"
    >
        <div class="px-6 py-5">
            <div
                v-if="!!slots.title"
                :class="['text-lg font-bold text-gray-800', titleClass]"
            >
                <slot name="title"/>
            </div>

            <div class="mt-4">
                <slot name="content"/>
            </div>
        </div>

        <div
            v-if="!!slots.footer"
            class="flex flex-row justify-end p-6 bg-gray-100 text-right"
        >
            <slot name="footer"/>
        </div>
    </Modal>
</template>
